import React from "react"
import { Link } from "react-router-dom"
import dp from "../../../images/profile_dp.png"

export default function Navbar(props) {
    const options = ['Dashboard', 'Tables', 'Approval', 'Customer Service', 'Logout']
    const optlink = ['dash', 'tables', 'approve', 'CS', 'logout']
    const navlist = options.map((option, index) => {
        const link = `/${optlink[index]}`
        if(props.page === option) {
            return <Link key={index} id="nav--list--select-btn" to={link}>{option}</Link>
        }
        else {
            return <Link key={index} to={link}>{option}</Link>
        }
    })
    return (
        <nav>
            <div className="nav--logo">
                <img src={dp} alt="operator-dp"></img>
                <h1>Operator Name</h1>
            </div>
            <div className="nav--list">
                {navlist}
            </div>
        </nav>
    )
}