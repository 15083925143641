import React from "react"
import { Link } from "react-router-dom"

export default function Logout({ setToken }) {
    const handleClick = async e => {
        e.preventDefault();
        setToken(null);
    }

    return (
        <div>
            <h1>Hi hello 123 start</h1>
            <Link to="/" onClick={handleClick}>Logout</Link>
        </div>
    )
}