import React from "react"
import Table from "./Table"
import fetchAPI from "../../fetchAPI"

export default function DisplayTable(props) {
    const options = ['Choose Table', 'User Accounts', 'Drivers', 'Customers', 'Available Drivers', 'Requests', 'Active Trips', 'Admins']
    const optionsValue = ['Tables', 'user_accounts', 'drivers', 'customers', 'available_drivers', 'requests', 'active_trips', 'admins']
    
    const data = props.data;
    const setData = props.setData;
    async function changeTable(e) {
        e.preventDefault();
        props.setTable(e.target.value);
        var dataArray;
        if(e.target.value === 'Tables') {
            setData([]);
            return
        }
        const apidata = await fetchAPI(e.target.value, "get");
        console.log(apidata);
        if(e.target.value === "user_accounts") {
            dataArray = apidata.data.getUsers.users;
        }
        else if(e.target.value === "drivers") {
            dataArray = apidata.data.getdrivers.drivers;
        }
        else if(e.target.value === "customers") {
            dataArray = apidata.data.getCustomers.customers;
        }
        else if(e.target.value === "available_drivers") {
            dataArray = apidata.data.getAvailableDrivers.available_drivers;
        }
        else if(e.target.value === "active_trips") {
            dataArray = apidata.data.getActiveTrips.trips;
        }
        else if(e.target.value === "requests") {
            dataArray = apidata.data.getRequests.requests;
        }
        else if(e.target.value === "admins") {
            dataArray = apidata.data.getAdmins.admins;
        }
        console.log(dataArray);
        setData(dataArray);
    }

    return (
        <div>
            <div className="title">
                <h1 id="tables-heading">Tables</h1>
                <select className="tables--title--drop-down-list" onChange={changeTable} defaultValue={props.table}>
                    {options.map((option, index) => {
                        return <option key={index} value={optionsValue[index]}>{option}</option>    
                    })}
                </select>
            </div>
            <div className="table-container">
                <div id="table">
                    <h1>{props.table}</h1>
                    {data && props.table !== "Tables" && <Table data={data} value={props.table} setEdit={props.setEdit} setRow={props.setRow}/>}
                </div>
            </div>
        </div>
    )
}