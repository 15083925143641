import React, { useState } from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import Tables from "./components/Pages/Tables"
import Dashboard from "./components/Pages/Dashboard"
import Login from "./components/Pages/Login"
import Approval from "./components/Pages/Approval"
import Service from "./components/Pages/Service"
import NewService from "./components/Pages/NewService"
// import Signup from "./components/Pages/Signup"
import Logout from "./components/Pages/Logout"
// import useToken from "./components/useToken"

export default function App() {
    const [token, setToken ] = useState(null);

    if(!token) {
        return <Login setToken={setToken} />
    }

    return (
        <div className="wrapper">
            <BrowserRouter>
                <Switch>
                    <Route path="/dash" component={Dashboard} />
                    <Route path="/tables" component={Tables} />
                    <Route path="/approve" component={Approval} />
                    <Route path="/CS" component={Service} />
                    <Route path="/newCS" component={NewService} />
                    {/* <Route path="/signup" component={Signup} /> */}
                    <Route path="/logout" render={() => <Logout setToken={setToken} />} />
                    <Route path="/*" component={Dashboard} />
                </Switch>
            </BrowserRouter>
        </div>
    )
}