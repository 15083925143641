import React from "react"
import headers from "../../tableheaders"
import fetchAPI from "../../fetchAPI"

export default function EditTable({setEdit, table, row, setRow}) {
    const columns = headers[table];
    var rowKeys = Object.keys(row);
    var rowValues = Object.values(row);

    console.log(rowKeys);
    console.log(rowValues);

    const handleSubmit = async e => {
        e.preventDefault();
        for (let i = 0; i < rowValues.length; i++) {
            row[rowKeys[i]] = rowValues[i];
        }
        setRow(row);
        const response = await fetchAPI(table, "edit", row);
        console.log(response);
        alert('Successfully updated table!');
        setEdit(false);
    }

    const handleClick = async e => {
        e.preventDefault();
        console.log(table);
        setEdit(false);
    }

    return (
        <div>
            <div className="title">
                <h1>Edit Table Entry</h1>
                <div onClick={handleClick}>Back</div>
            </div>
            <div className="cs-wrapper">
                <form onSubmit={handleSubmit}>
                    <div className="csform">
                        {columns && columns.map((column, index) => {
                            return (
                                <div>
                                    <h3>{column}:</h3>
                                    <input type="text" onChange={e => {rowValues[index] = (e.target.value)}} defaultValue={rowValues[index]} />
                                </div>
                        )})}
                    </div>
                    <div className="csbottom">
                        <input type="submit" placeholder="Continue" />
                    </div>
                </form>
            </div>
        </div>
    )
}