import React, { useState } from "react"
import Navbar from "./Comps/Navbar"
import { Link } from "react-router-dom"

export default function Service() {
    
    const [Name, setName] = useState("")
    const [Plate, setPlate] = useState("")
    const [Driver, setDriver] = useState("")
    const [Load, setLoad] = useState("")
    const [Status, setStatus] = useState("")
    const [Fuel, setFuel] = useState("")
    const [Location, setLocation] = useState("")

    const handleSubmit = async e => {
        e.preventDefault();
        const obj = {Name:Name, Plate:Plate, Driver:Driver, Load:Load, Status:Status, Fuel:Fuel, Location:Location};
        alert("Added Vehicle " + Name + " Successfully");
        console.log(obj);
    }

    return (
        <div>
            <Navbar page="Customer Service" />
            <div className="title">
                <h1>Add new Service</h1>
                <Link to="/CS">Back</Link>
            </div>
            <div className="cs-wrapper">
                <form onSubmit={handleSubmit}>
                    <div className="csform">
                        Name: <br></br>
                        <input type="text" onChange={e => setName(e.target.value)} />
                        <br></br>
                        <br></br>
                        Plate Number: <br></br>
                        <input type="text" onChange={e => setPlate(e.target.value)}/>
                        <br></br>
                        <br></br>
                        Driver: <br></br>
                        <input type="text" onChange={e => setDriver(e.target.value)}/>
                        <br></br>
                        <br></br>
                        Load: <br></br>
                        <input type="text" onChange={e => setLoad(e.target.value)}/>
                        <br></br>
                        <br></br>
                        Status: <br></br>
                        <input type="text" onChange={e => setStatus(e.target.value)}/>
                        <br></br>
                        <br></br>
                        Fuel: <br></br>
                        <input type="text" onChange={e => setFuel(e.target.value)}/>
                        <br></br>
                        <br></br>
                        Current Location: 
                        <br></br><input type="text" onChange={e => setLocation(e.target.value)}/>
                    </div>
                    <div className="csbottom">
                        <input type="submit" placeholder="Continue" />
                    </div>
                </form>
            </div>
        </div>
    )
}