import React from "react"
import Navbar from "./Comps/Navbar"

export default function Dashboard() {

    return (
        <div className="dashboard-wrapper">
            <Navbar page="Dashboard" />
            <div className="title">
                <h1>Dashboard</h1>
                <p>User Mode : <strong>Admin</strong></p>
            </div>
        </div>
    )
}