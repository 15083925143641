import React, { useState } from "react"
import Navbar from "./Comps/Navbar"
import DisplayTable from "./Comps/DisplayTable"
import EditTable from "./Comps/EditTable"

export default function Tables() {
    const [edit, setEdit] = useState(false);
    const [table, setTable] = useState("Tables");
    const [row, setRow] = useState({});
    const [data, setData] = useState([]);

    return (
        <div className="tables">
            <Navbar page="Tables" />
            {!edit && <DisplayTable setEdit={setEdit} table={table} setTable={setTable} setRow={setRow} data={data} setData={setData} />}
            {edit && <EditTable setEdit={setEdit} table={table} row={row} setRow={setRow} />}
        </div>
    )
}