import React, { useState } from "react"
import PropTypes from "prop-types"
import fetchAPI from "../fetchAPI"

export default function Login({ setToken }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    function checkAdmin(data) {
        return (data.name === username || data.phone_number === username);
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const adminAccounts = await fetchAPI("admins", "get");
        console.log(adminAccounts);
        const adminData = adminAccounts.data.getAdmins.admins;
        const admin = adminData.find(checkAdmin);
        if(admin) {
            if(admin.email === password) {
                setToken({token: {username, password}});
            } else {
                alert("Wrong password");
            }
        }else {
            alert("Please enter valid credentials");
        }
    }

    return (
        <div className="login-wrapper">
            <div className="top-container">
                <h1>OptRoute - Intracity - Admin page</h1>
            </div>
            <div className="middle1">
                <h1>Login</h1>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="middle2">
                    <input type="username"  placeholder="Username / email or phone number" onChange={e => setUsername(e.target.value)} />
                </div>
                <div className="middle2">    
                    <input type="password"  placeholder="Password" onChange={e => setPassword(e.target.value)}/>
                </div>
                <div className="bottom">
                    <input type="submit" placeholder="Continue" />
                </div>
            </form>
        </div>
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}