const headers = {
    user_accounts: [
        'User ID', 
        'First Name', 
        'Last Name', 
        'Phone', 
        'OS', 
        'Device ID', 
        'Type', 
        'Account Creation Date'
    ],
    drivers: [
        'Driver ID',
        'User ID', 
        'KYC', 
        'Status', 
        'Ready for Approval', 
        'Approved', 
        'Account Creation Date', 
        'Valid Till', 
        'Subscription Type', 
        'Subscription Price', 
        'Next Payment Due On', 
        'First Name', 
        'Last Name', 
        'Email', 
        'Address', 
        'Operation ID', 
        'Vehicle ID', 
        'Driver Photo URL', 
        'DL Number', 
        'Insurance Image URL', 
        'Commercial Image URL', 
        'Rating', 
        'Number of Trips Completed', 
        'Level', 
        'Adhaar Image URL', 
        'Pan Image URL', 
        'DOB', 
        'Payment Method 1', 
        'Payment Method 2', 
        'Payment Method 3', 
        'Default Payment Method', 
        'Wallet Balance', 
        'Delivery'
    ],
    customers: [
        'User ID',
        'Name',
        'Email',
        'Phone',
        'Ration',
        'Payment Method 1',
        'Payment Method 2',
        'Payment Method 3',
        'Default Payment Method',
        'Wallet Balance',
        'DOB'
    ],
    available_drivers: [
        'Row ID',
        'Driver ID',
        'Latitude',
        'Longitude',
        'Timestamp',
        'Vehicle Type',
        'Total Payload Capacity',
        'Available Capacity',
        'Pincode',
        'Service Area',
        'Ward',
        'Zone'
    ],
    active_trips: [
        'Trip ID',
        'Request ID',
        'Secure OTP',
        'Pickup Latitude',
        'Pickup Longitude',
        'Dropoff Latitude',
        'Dropoff Longitude',
        'Driver Start Latitude',
        'Driver Start Longitude',
        'Driver Live Latitude',
        'Driver Live Longitude',
        'Trip Start Time',
        'Trip End Time',
        'Distance',
        'Driver ID',
        'Vehicle ID',
        'Vehicle Type',
        'Customer ID',
        'Driver Rating',
        'Customer Rating',
        'Base Fare',
        'Total Toll Charge',
        'Total Trip Fare',
        'SGST Amount',
        'CGST Amount',
        'Payment Gateway Charges',
        'Amount Paid to Driver',
        'Paid to Driver Directly',
        'Paid to Company',
        'Payment Mode',
        'Trip Status',
        'Payment Status',
        'Driver Message',
        'Passenger Message'
    ],
    requests: [
        'Request ID',
        'Customer ID',
        'Pickup Latitude',
        'Pickup Longitude',
        'Pickup Pincode',
        'Pickup Service Area',
        'Pickup Ward',
        'Pickup Zone',
        'Dropoff Latitude',
        'Dropoff Longitude',
        'Dropoff Pincode',
        'Dropoff Service Area',
        'Dropoff Ward',
        'Dropoff Zone',
        'Timestamp',
        'Package Category',
        'Package Sub Category',
        'Weight',
        'L',
        'H',
        'B',
        'Payment Mode'
    ],
    admins: [
        'User ID', 
        'Name', 
        'Phone Number', 
        'Email'
    ]
};

export default headers;