import React from "react"
import Navbar from "./Comps/Navbar"
import { Link } from "react-router-dom"


export default function Service() {

    return (
        <div>
            <Navbar page="Customer Service" />
            <div className="title">
                <h1>Service</h1>
                <div className="cs_add"><Link to="/newCS">+ &nbsp; Add new Consignment</Link></div>
            </div>
        </div>
    )
}