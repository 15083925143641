import React from "react";
import headers from "../../tableheaders";
import fetchAPI from "../../fetchAPI";

export default function DriverTab({data}){

	const approveKYC = async () => {
		data.kyc = true;
		data.ready_for_approval = true;
		data.status = "KYC approved, ready for approval";
		const response = await fetchAPI("drivers", "edit", data);
		console.log(response);
	}
	const approveAccount = async () => {
		data.approved = true;
		data.status = "Account approved";
		const response = await fetchAPI("drivers", "edit", data);
		console.log(response);
	}

	return(
	<div className="dash-table">

		{Object.values(data).map( (value, index) => <p><strong>{headers["drivers"][index]} : </strong> {value}</p> )}
		<button onClick={approveKYC}>Approve KYC</button>
		<button onClick={approveAccount}>Approve Account</button>

	</div>
)}
