import React from "react";
import headers from "../../tableheaders";

export default function Table(props) {
    const columns = headers[props.value];

    return (
        <div className="scrollit">
            <table className="table-body">
                <thead>
                    <tr key="thead" className="table-header">
                        {columns && columns.map(column => <th>{column}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {props.data.map((item, index) => {
                        return (
                            <tr key={index} className="table-content">
                                {Object.values(item).map(atr => <td>{atr}</td>)}
                                <div className="edit-button" onClick={() => {props.setRow(item);props.setEdit(true);}}>Edit</div>
                            </tr>
                        )       //(Object.values(item))[index]
                    })}
                </tbody>
            </table>
            
        </div>
    );
}