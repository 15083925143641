import queries from './queries';
import primarykey from "./primarykey";

export default async function fetchAPI(table, type, data) {
    var query = queries[type][table];
    var variables = {}

    if (data) {
        variables[primarykey[type][table]] = data[primarykey[table]];
        var newdata = data;
        delete newdata[primarykey[table]];
        if(table === "drivers") {
            delete newdata["account_creation_date"];
        }
        variables["data"] = newdata;
        console.log(variables);
    }

    var myHeaders = new Headers();  
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Access-Control-Allow-Origin", "*");

    var graphql = JSON.stringify({
        query: query,
        variables: variables
    })
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: graphql,
        redirect: 'follow'
    };

    console.log(requestOptions);

    return fetch("https://api.optroute.in/", requestOptions)
    .then(response => response.json())

}