import React, { useState } from "react"
import Navbar from "./Comps/Navbar"
import DriverTab from "./Comps/DriverTab"
import fetchAPI from "../fetchAPI"

export default function Approval() {
    const [selectIndex, setSelectIndex] = useState(0);
    const [driverDataArray, setDriverDataArray] = useState([]);
    const [driverData, setDriverData] = useState();

    const handleClick = async () => {
        setDriverData([]);
        const driverData = await fetchAPI("drivers", "get");
        console.log(driverData);
        setDriverDataArray(driverData.data.getdrivers.drivers);
    }

    const driverButtons = driverDataArray.map( (data, index) => {

        const handleClick = () => {
            setSelectIndex(index);
            setDriverData(data);
        }

        var type = "approval-button"
        if(index === selectIndex)
            type = "approval-button-selected"
        if(!data.approved) {
            return (
                <div onClick={handleClick} className={type} >
                    <strong>{data.first_name}</strong>
                    <p>{data.city}</p>
                    <p>{data.user_id}</p>
                </div>
            )
        }
    });
    
    return (
        <div className="approval-wrapper">
            <Navbar page="Approval" />
            <div className="title">
                <h1>Approval</h1>
                <button onClick={handleClick}>Click Here</button>
                <p>User Mode : <strong>Admin</strong></p>
            </div>
            
            <div className="approval">
                <div className="approval-button-container">
                    {driverButtons}
                </div>
                {driverData && <DriverTab data={driverData}/>} 
            </div>
        </div>
    )
}