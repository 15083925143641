const primarykey = {
    user_accounts: "user_id",
    drivers: "driver_id",
    customers: "customer_id",
    available_drivers: "row_id",
    active_trips: "trip_id",
    requests: "request_id",
    admins: "user_id",

    edit: {
        user_accounts: "userId",
        drivers: "driverId",
        customers: "customerId",
        available_drivers: "rowId",
        active_trips: "tripId",
        requests: "requestId",
        admins: "userId"
    }
}

export default primarykey;