const queries = {
    get: {
        login: "mutation Deletedriver($data: LoginInput!) {\r\n  login(data: $data) {\r\n    user_id\r\n    firstName\r\n    lastName\r\n    phone_number\r\n    os\r\n    device_id\r\n    type\r\n    email\r\n    account_creation_date\r\n    role\r\n  }\r\n}",
        user_accounts: "query Query {\r\n  getUsers {\r\n    users {\r\n      user_id\r\n      firstName\r\n      lastName\r\n      phone_number\r\n    os\r\n      device_id\r\n      type\r\n      email\r\n      account_creation_date\r\n    role\r\n    }\r\n  count\r\n     }\r\n}",
        drivers: "query Query {\r\n  getdrivers {\r\n    drivers {\r\n      driver_id\r\n      user_id\r\n      kyc\r\n      status\r\n      ready_for_approval\r\n      approved\r\n      account_creation_date\r\n      subsciption_type\r\n      valid_till\r\n      subscription_price\r\n      next_payment_due_on\r\n      first_name\r\n      last_name\r\n      email\r\n      address\r\n      operator_id\r\n      vehicle_id\r\n      driver_photo_url\r\n      dl_number\r\n      insurance_image_url\r\n      commercial_iamge_url\r\n      rating\r\n      number_of_trips_completed\r\n      level\r\n      adhaar_image_url\r\n      pan_image_url\r\n      dob\r\n      payment_method_1\r\n      payment_method_2\r\n      payment_method_3\r\n      default_payment_method\r\n      wallet_balance\r\n      delivery\r\n    }\r\n    count\r\n  }\r\n}",
        customers: "query GetCustomers {\r\n  getCustomers {\r\n    customers {\r\n      customer_id\r\n      user_id\r\n      name\r\n      phone_number\r\n      email\r\n      ration\r\n      payment_method_1\r\n      payment_method_2\r\n      payment_method_3\r\n      default_payment_method\r\n      wallet_balance\r\n      dob\r\n    }\r\n    count\r\n  }\r\n}",
        available_drivers: "query GetAvailableDrivers {\r\n  getAvailableDrivers {\r\n    available_drivers {\r\n      row_id\r\n      driver_id\r\n      latitude\r\n      longitude\r\n      timestamp\r\n      vehicle_type\r\n      total_payload_capacity\r\n      available_capacity\r\n      pincode\r\n      service_area\r\n      ward\r\n      zone\r\n    }\r\n    count\r\n  }\r\n}",
        active_trips: "query Query {\r\n  getActiveTrips {\r\n    count\r\n    trips {\r\n      trip_id\r\n      request_id\r\n      secure_ofp\r\n      pickup_latitude\r\n      pickup_longitude\r\n      dropoff_latitude\r\n      dropoff_longitude\r\n      driver_start_latitude\r\n      driver_start_longitude\r\n      driver_live_latitude\r\n      driver_live_longitude\r\n      trip_start_time\r\n      trip_end_time\r\n      distance\r\n      vehicle_id\r\n      driver_id\r\n      vehicle_type\r\n      customer_id\r\n      customer_rating\r\n      driver_rating\r\n      base_fare\r\n      total_toll_charge\r\n      total_trip_fare\r\n      sgst_amount\r\n      cgst_amount\r\n      payment_gateway_charges\r\n      amount_paid_to_driver\r\n      paid_to_driver_directly\r\n      paid_to_company\r\n      payment_mode\r\n      trip_status\r\n      payment_status\r\n      driver_message\r\n      passenger_message\r\n    }\r\n  }\r\n}",
        requests: "query GetRequests {\r\n  getRequests {\r\n    requests {\r\n      request_id\r\n      customer_id\r\n      pickup_latitude\r\n      pickup_longitude\r\n      pickup_pincode\r\n      pickup_service_area\r\n      pickup_ward\r\n      puckup_zone\r\n      dropoff_latitude\r\n      dropoff_longitude\r\n      dropoff_pincode\r\n      dropoff_service_area\r\n      dropoff_ward\r\n      dropoff_zone\r\n      timestamp\r\n      package_category\r\n      package_sub_category\r\n      weight\r\n      L\r\n      H\r\n      B\r\n      payment_mode\r\n    }\r\n    count\r\n  }\r\n}",
        admins: "query GetAdmins {\r\n  getAdmins {\r\n    admins {\r\n      user_id\r\n      name\r\n      phone_number\r\n      email\r\n    }\r\n    count\r\n  }\r\n}"
    },
    add: {
        user_accounts: "mutation Mutation($data: UserAccountInput!) {\r\n  addUserAccount(data: $data)\r\n}",
        drivers: "mutation Adddriver($data: driverInput!) {\r\n  adddriver(data: $data)\r\n}",
        customers: "mutation AddCustomers($data: CustomerInput!) {\r\n  addCustomers(data: $data)\r\n}",
        available_drivers: "mutation AddAvailableDrivers($data: AvaiableDriversInput!) {\r\n  addAvailableDrivers(data: $data)\r\n}",
        active_trips: "mutation Mutation($data: addActiveTripInput!) {\r\n  addActiveTrip(data: $data)\r\n}",
        requests: "mutation Mutation($data: addRequestInput!) {\r\n  addRequests(data: $data)\r\n}",
        admins: "mutation AddAdmins($data: AdminAccountsInput!) {\r\n  addAdmins(data: $data)\r\n}"
    },
    edit: {
        active_trips: "mutation Mutation($tripId: String!, $data: updateActiveTripInput!) {\r\n  updateActiveTrip(trip_id: $tripId, data: $data)\r\n}",
        admins: "mutation Updateadmin($userId: String!, $data: updateAdminAccountInput!) {\r\n  updateadmin(user_id: $userId, data: $data)\r\n}",
        drivers: "mutation Updatedriver($driverId: String!, $data: updateDriverInput!) {\r\n  updatedriver(driver_id: $driverId, data: $data)\r\n}",
        customers: "mutation UpdateCustomer($data: updateCustomerInput!, $customerId: String!) {\r\n  updateCustomer(data: $data, customer_id: $customerId)\r\n}",
        available_drivers: "mutation Updateavailabledriver($rowId: String!, $data: updateAvailableDriversInput!) {\r\n  updateavailabledriver(row_id: $rowId, data: $data)\r\n}",
        requests: "mutation AddRequests($requestId: String!, $data: updateRequestsInput!) {\r\n  updateRequest(request_id: $requestId, data: $data)\r\n}"
    },
    delete: {
        active_trips: "mutation DeleteActiveTrip($tripId: String!) {\r\n  deleteActiveTrip(trip_id: $tripId)\r\n}",
        drivers: "mutation Deletedriver($driverId: String!) {\r\n  deletedriver(driver_id: $driverId)\r\n}",
        customers: "mutation DeleteCustomer($customerId: String!) {\r\n  deleteCustomer(customer_id: $customerId)\r\n}",
        available_drivers: "mutation Deleteavailabledriver($rowId: String!) {\r\n  deleteavailabledriver(row_id: $rowId)\r\n}",
        requests: "mutation DeleteRequest($requestId: String!) {\r\n  deleteRequest(request_id: $requestId)\r\n}",
        admins: "mutation Deleteadmin($userId: String!) {\r\n  deleteadmin(user_id: $userId)\r\n}"
    }
}

export default queries;